import React, { useEffect, useMemo, useState } from 'react';
import { CartProvider } from 'use-shopping-cart'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { countMessages, hasFeature, getUser, getUserSubscriptionStatus, countUnseenInternalMessages } from './api';
import { AppContext } from './context';
import register from "./swManager";
import Lazy from './lazy';
import i18n, { getResolvedLanguage } from './i18n';
import '@popperjs/core';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/app.scss';
import './css/index.scss';
import MessagesRoutes from './navigation/messagesRoutes';
import InternalMessagesRoutes from './navigation/internalMessagesRoutes';

import OutGoingCallLeaveMessage from './routes/outgoing-call/outGoingCallLeaveMessage';

const PublicRoutes = React.lazy(() => import('./navigation/publicRoutes'));
const PrivateLayout = React.lazy(() => import('./routes/layout/privateLayout'));
const CallLayout = React.lazy(() => import('./routes/layout/callLayout'));
const WebviewLayout = React.lazy(() => import('./routes/layout/webviewLayout'));
const Home = React.lazy(() => import('./routes/protected/home'));
const CallsLog = React.lazy(() => import('./routes/protected/log'));
const OutgoingCall = React.lazy(() => import('./routes/outgoing-call/outgoingCall'));
const IncomingCall = React.lazy(() => import('./routes/protected/incoming-call/incomingCall'));
const PlacesRoutes = React.lazy(() => import('./navigation/placesRoutes'));
const ProfileRoutes = React.lazy(() => import('./navigation/profileRoutes'));
const SubscriptionsRoutes = React.lazy(() => import('./navigation/subscriptionsRoutes'));
const PurchaseRoutes = React.lazy(() => import('./navigation/purchaseRoutes'));
const ShopRoutes = React.lazy(() => import('./navigation/shopRoutes'));
const OrdersRoutes = React.lazy(() => import('./navigation/ordersRoutes'));
const PlacePrint = React.lazy(() => import('./routes/protected/place/placePrint'));
const NewScan = React.lazy(() => import('./routes/protected/newScan'));
const SettingsRoutes = React.lazy(() => import('./navigation/settingsRoutes'));
const ManagePaymentView = React.lazy(() => import('./routes/protected/managePayment'));
const Logout = React.lazy(() => import('./routes/logout'));
const EmailNotVerified = React.lazy(() => import('./routes/protected/emailNotVerified'));
const AcceptInvitation = React.lazy(() => import('./routes/acceptInvitation'));
const Report = React.lazy(() => import('./routes/protected/report'));
const Info = React.lazy(() => import('./routes/protected/info'));

function App() {

    const [featureMissedCallMessage, setFeatureMissedCallMessage] = useState<boolean>(false);
    const [needRefreshNewMessageCount, setNeedRefreshNewMessageCount] = useState<boolean>(false);
    const [newMessageCount, setNewMessageCount] = useState<number>(0);
    const [needRefreshNewInternalMessageCount, setNeedRefreshNewInternalMessageCount] = useState<boolean>(false);
    const [newInternalMessageCount, setNewInternalMessageCount] = useState<number>(0);
    const [subscriptionStatus, setSubscriptionStatus] = useState<any|null>();
    const appContext = useMemo(
        () => ({
            featureMissedCallMessage, setFeatureMissedCallMessage, 
            setNeedRefreshNewMessageCount, newMessageCount,
            setNeedRefreshNewInternalMessageCount, newInternalMessageCount,
            subscriptionStatus
        }),[
            featureMissedCallMessage, setFeatureMissedCallMessage, 
            setNeedRefreshNewMessageCount, newMessageCount,
            setNeedRefreshNewInternalMessageCount, newInternalMessageCount, 
            subscriptionStatus
        ]);

    const checkNewInternalMessages = async () => {
        const data:any = await countUnseenInternalMessages();
        const unseen = data?.unseen || 0;
        setNewInternalMessageCount(unseen);
    }

    const checkNewMessages = async () => {
        const data:any = await countMessages();
        const unseen = data?.unseen | 0;
        setNewMessageCount(unseen)
    }

    useEffect(() => {
        getUser()
        .then(user => {

            if(user && user.lang && user.lang !== getResolvedLanguage() ) {
                i18n.changeLanguage(user.lang);
            }

            if (user) {
                (async () => {
                    setNeedRefreshNewInternalMessageCount( true )
                    setFeatureMissedCallMessage( await hasFeature("messages") )
                    setNeedRefreshNewMessageCount( true )
                })()
            }

            register();
        });

        setInterval(checkNewInternalMessages, 120 * 1000);
        if (featureMissedCallMessage) {
            setInterval(checkNewMessages, 300 * 1000);
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (needRefreshNewMessageCount) {
                if (featureMissedCallMessage) {
                    checkNewMessages();
                }
                setNeedRefreshNewMessageCount(false);
            }
        })()
    }, [needRefreshNewMessageCount])

    useEffect(() => {
        (async () => {
            if (needRefreshNewInternalMessageCount) {
                checkNewInternalMessages();
                setNeedRefreshNewInternalMessageCount(false);
            }
        })()
    }, [needRefreshNewInternalMessageCount])

    useEffect(() => {
        getUserSubscriptionStatus()
            .then((result) => 
                setSubscriptionStatus(result || null));
    }, [])

    return (
        <CartProvider
            cartMode="checkout-session"
            stripe=""
            currency="EUR"
            shouldPersist={true}
            persistKey="porthelloShop"
            loading={<p aria-live="polite">Loading...</p>}
        >
            <AppContext.Provider value={appContext}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/*" element={<Lazy><PublicRoutes /></Lazy>} />
                        <Route element={<Lazy><PrivateLayout /></Lazy>}>
                            <Route path="home" element={<Lazy><Home /></Lazy>} />
                            <Route path="log" element={<Lazy><CallsLog /></Lazy>} />
                            <Route path="places/*" element={<Lazy><PlacesRoutes /></Lazy>} />
                            <Route path="messages/*" element={<Lazy><MessagesRoutes /></Lazy>} />
                            <Route path="internalMessages/*" element={<Lazy><InternalMessagesRoutes /></Lazy>} />
                            <Route path="profile/*" element={<Lazy><ProfileRoutes /></Lazy>} />
                            <Route path="subscription/*" element={<Lazy><SubscriptionsRoutes /></ Lazy>} />
                            <Route path="manage/payment" element={<Lazy><ManagePaymentView /></Lazy>} />
                            <Route path="settings/*" element={<Lazy><SettingsRoutes /></Lazy>} />
                            <Route path='logout' element={<Lazy><Logout /></Lazy>} />
                            <Route path='email_not_verified' element={<Lazy><EmailNotVerified /></Lazy>} />
                            <Route path='new_scan' element={<Lazy><NewScan /></Lazy>} />
                            <Route path="accept_invitation" element={<Lazy><AcceptInvitation /></Lazy>} />
                            <Route path="report" element={<Lazy><Report /></Lazy>} />
                            <Route path="info" element={<Lazy><Info /></Lazy>} />
                            <Route path="shop/*" element={<Lazy><ShopRoutes /></Lazy>} />
                            <Route path="purchase/*" element={<Lazy><PurchaseRoutes /></Lazy>} />
                            <Route path="orders/*" element={<Lazy><OrdersRoutes /></Lazy>} />
                        </Route>
                        <Route element={<Lazy><CallLayout /></Lazy>}>
                            <Route path='call/:token' element={<Lazy><OutgoingCall /></Lazy>} />
                            <Route path='incoming/:callId' element={<Lazy><IncomingCall /></Lazy>} />
                        </Route>
                        <Route path="webview" element={<Lazy><WebviewLayout /></Lazy>}>
                            <Route path=":placeId/print/:elocodeId" element={<Lazy><PlacePrint /></Lazy>} />
                            <Route path="shop/*" element={<Lazy><ShopRoutes /></Lazy>} />
                            <Route path="manage/payment" element={<Lazy><ManagePaymentView /></Lazy>} />
                            <Route path="orders/*" element={<Lazy><OrdersRoutes /></Lazy>} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AppContext.Provider>
        </CartProvider>);
}

export default App