import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import frTranslations from './locales/fr/main.json';
import esTranslations from './locales/es/main.json';
import enTranslations from './locales/en/main.json';

i18n.use(initReactI18next)
    .init({
        compatibilityJSON: 'v4',
        resources: {
            fr: { main: frTranslations },
            es: { main: esTranslations },
            en: { main: enTranslations }
        },
        lng: navigator.language,
        fallbackLng: 'en',
        nonExplicitSupportedLngs: true,
        debug: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;

export function getResolvedLanguage() {
    return i18n.resolvedLanguage.split('-')[0];
}