import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import '../../css/spinner.scss';

function Spinner() {

    const { t } = useTranslation('main');

    return (
        <main className="flex-grow-1 main-container page-center-spinner spinner">  
            <div className="d-flex flex-column">
                <FontAwesomeIcon icon={faCircleNotch} className="big-icon fa-spin m-3"/>
                {t("global.loading.message", "Chargement des données ...")}
            </div>
        </main>
    );
}

export default Spinner;