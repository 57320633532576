import { createContext } from 'react';

interface AppContextI {
  setNeedRefreshNewMessageCount: (needed: boolean) => void
  featureMissedCallMessage: boolean
  setFeatureMissedCallMessage: (enable: boolean) => void
  newMessageCount: number
  setNeedRefreshNewInternalMessageCount: (needed: boolean) => void
  newInternalMessageCount: number
  subscriptionStatus: {
    card: {
      aboutToExpire: boolean
      expired: boolean
      last4: string
    },
    latestInvoice: {
      amountDue: number
      currency: string
      attempt: number
      isLate: boolean
      isPaid: boolean
    }
  } | null
}

export const AppContext = createContext<AppContextI>({
  setNeedRefreshNewMessageCount: (needed: boolean)=>{},
  featureMissedCallMessage: false,
  setFeatureMissedCallMessage: (enable: boolean)=>{},
  newMessageCount: 0,
  setNeedRefreshNewInternalMessageCount: (needed: boolean)=>{},
  newInternalMessageCount: 0,
  subscriptionStatus: null
});