import React from 'react';
import ReactDOM from 'react-dom/client';
import { setConf } from './config';
import reportWebVitals from './reportWebVitals';
import App from './app';

// Read data attributes
const $root = document.getElementById('root') as HTMLElement;
const callBaseUrl = $root.dataset.callBaseUrl;
const apiBaseUrl = $root.dataset.apiBaseUrl;
const wsBaseUrl = $root.dataset.wsBaseUrl;
const autoCall = $root.dataset.autoCall;
const appId = $root.dataset.appId;
const appKey = $root.dataset.appKey;
const callError = $root.dataset.callError;
const stripeKey = $root.dataset.stripeKey;
const root = ReactDOM.createRoot($root);

setConf ( {
    callBaseUrl: callBaseUrl,
    apiBaseUrl: apiBaseUrl,
    wsBaseUrl: wsBaseUrl,
    autoCall: autoCall === "true" ? true : false,
    appId: appId,
    appKey: appKey,
    callError: callError,
    stripeKey: stripeKey
} );

root.render(
    <App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();