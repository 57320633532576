import { ReactNode } from "react";

interface EmptyListPropsI {
    lines: number,
    message: string | ReactNode
}

function EmptyList(props: EmptyListPropsI) {
    return (
        <div className="empty-list">
            <ul className="list-group w-100">
                {new Array(props.lines).fill("").map((value, index) =>
                    <li key={index} className="list-group-item">&nbsp;</li>)}
            </ul>
            <div className="empty-list-message text-prewrap">
                {props.message}
            </div>
            {/*
            <div className="empty-list-badge">
                <div className="image"></div>
                <div>{props.message}</div>
            </div>
            */}
        </div>
    );
}

export default EmptyList;