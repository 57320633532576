import localForage from "localforage";

localForage.config({
    name: 'gate',
    version: 1.0
});

export async function set(key: string, value: any): Promise<any> {
    try {
        await localForage.setItem(key, value);
        return value;
    } catch (err) {
        console.log(err);
    }
}

export async function get(key: string): Promise<any> {
    try {
        return await localForage.getItem(key);
    } catch (err) {
        console.log(err);
    }
}

export async function remove(key: string): Promise<void> {
    try {
        return await localForage.removeItem(key);
    } catch (err) {
        console.log(err);
    }
}