const applicationServerPublicKey = "BCGeEf6DCTf07ZaRojQo_wnSztMsioFctiSEq18RBbnHeoM9_0PbnrF9Yqsxl1VU_KMK07S34OD83K8eQFNJHi0";
let swRegistration: ServiceWorkerRegistration|undefined;

class WebPushError extends Error {

    name: string
    status: string
    message: string
    type: string
    stack: any

    constructor(message: string, type: string, stack?: any) {
        super();
        this.name = "WebPushError";
        this.status = "000";
        this.type = type;
        this.message = message;
        this.stack = stack;
    }
}

function urlB64ToUint8Array(base64String: string) {

    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob ( base64 );
    const outputArray = new Uint8Array ( rawData.length );

    for (let i = 0; i < rawData.length; ++i){
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}

export default function register() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.register('/sw.js?v=0.8')
            .then ((swReg: ServiceWorkerRegistration) => {
                //console.log('Service Worker is registered', swReg);
                swRegistration = swReg;
                swReg.update();
            } )
            .catch((error: any) => {
                console.error ( 'Service Worker Error', error );
            });
    }
}

export function getSubscription() {
    if (swRegistration) {
        return swRegistration.pushManager.getSubscription ()
            .then ((subscription) => {
                return subscription ? JSON.stringify(subscription) : undefined;
            })
    } else {
        return Promise.reject(new WebPushError("No Service Worker registered", "NoSWRegistered"));
    }
}

export function isSubscribed() {
    if (swRegistration) {
        return swRegistration.pushManager.getSubscription ()
            .then ((subscription) => {
                return subscription ? true : false;
            })
    } else {
        return Promise.reject(new WebPushError("No Service Worker registered", "NoSWRegistered"));
    }
}

export function subscribeToPush() {

    if (swRegistration) {

        if (Notification.permission === 'denied' || !swRegistration) {
            return Promise.reject(new WebPushError("Failed to unsubscribe", Notification.permission));
        }

        const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);

        return swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
        })
            .then ((subscription) => {
                return JSON.stringify(subscription);
            })
            .catch((error) => {
                throw new WebPushError("Failed to subscribe", Notification.permission, error.stack);
            });
    } else {
        return Promise.reject(new WebPushError("No Service Worker registered", "NoSWRegistered"));
    }
}

export function unsubscribeFromPush() {
    if(swRegistration) {
        return swRegistration.pushManager.getSubscription ()
            .then(async (subscription) => {
                if(!subscription) {
                    throw new WebPushError("No subscription found", "NotFound");
                }
                await subscription.unsubscribe ();
                return JSON.stringify(subscription);
            })
            .catch ((error) => {
                throw new WebPushError("Failed to unsubscribe", Notification.permission, error.stack);
            })

    } else {
        return Promise.reject(new WebPushError("No Service Worker registered", "NoSWRegistered"));
    }
}

export function isPushManagerAvailable() {
    return swRegistration && swRegistration.pushManager ? true : false
}