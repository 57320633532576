import React from 'react';
import { Routes, Route } from "react-router-dom";
import InternalMessagesList from '../routes/protected/internalMessages';


export default function MessagesRoutes() {
    return (
        <Routes>
            <Route index element={<InternalMessagesList />} />
        </Routes>
    )
}