import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid'
import { Modal } from 'bootstrap';
import PubSub from 'pubsub-js';

interface ModalPropsI {
    id?: string
    title: string
    children?: React.ReactNode
    show?: boolean
    actionLabel: React.ReactNode|string
    handleSubmit?: (event: React.FormEvent<HTMLFormElement>, modal: Modal|null) => void
    handleOnHide?: (modal: Modal|null) => void
    handleOnShow?: (modal: Modal|null) => void
}

interface ModalConfirmPropsI {
    id: string
    title: string
    children: React.ReactNode
    actionLabel: React.ReactNode|string
    handleConfirm: (confirm: boolean) => void
}

interface ModalNotifyPropsI {
    id: string
    title: string
    children: React.ReactNode
    actionLabel: React.ReactNode|string
    handleClose: () => void
}

interface ModalListPropsI {
    id: string
    description: string
    list: Array<any>
    handleAction: (item?: any) => void
}

export function ModalForm(props: ModalPropsI) {

    const { t } = useTranslation('main');
    let modalRef = useRef<HTMLDivElement>(null);

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        if(props.handleSubmit && modalRef.current) {
            const modalCpt = Modal.getInstance(modalRef.current);
            props.handleSubmit(event, modalCpt);
        }
    }

    useEffect(() => {
        if(modalRef.current) {
            const modalCpt = Modal.getOrCreateInstance(modalRef.current, {
                backdrop: 'static'
            });
            modalRef.current.addEventListener('hidden.bs.modal', function (event) {
                let form = document.querySelector(`#${props.id} form`) as HTMLFormElement
                if(form) {
                    form.reset();
                    form.classList.remove( "was-validated" );
                }

                if(props.handleOnHide) {
                    props.handleOnHide(modalCpt);
                }
            });

            modalRef.current.addEventListener('shown.bs.modal', function (event) {
                if(props.handleOnShow) {
                    props.handleOnShow(modalCpt);
                }
            })

            if(props.show) {
                modalCpt.show()
            }
        }
    });

    return (
        <div ref={modalRef} className="modal" tabIndex={-1} id={props.id} >
            <div className="modal-dialog modal-dialog-centered" data-backdrop="false">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">{props.title}</h5>
                        </div>
                        <div className="modal-body">
                            {props.children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">
                                {t("global.modal.button.cancel", "Annuler")}
                            </button>
                            <button type="submit" className="btn btn-sm btn-primary">{props.actionLabel}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export function ModalConfirm(props: ModalConfirmPropsI) {

    const { t } = useTranslation('main');
    let modalRef = useRef<HTMLDivElement>(null);

    function close(){
        if(modalRef.current) {
            const modalCpt = Modal.getInstance(modalRef.current);
            if(modalCpt) {
                modalCpt.hide();
            }
        }

        PubSub.publish("modal.remove", props.id);
    }

    function handleNo() {
        props.handleConfirm(false);
        close();
    }

    function handleYes() {
        props.handleConfirm(true);
        close();
    }

    useEffect(() => {
        if(modalRef.current) {
            const modal = Modal.getOrCreateInstance(modalRef.current, {
                backdrop: 'static'
            });
            modal.show();
        }
    });

    return (
        <div ref={modalRef} className="modal" tabIndex={-1} id={props.id} data-backdrop="static">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-secondary" onClick={handleNo}>
                            {t("global.modal.button.cancel", "Annuler")}
                        </button>
                        <button type="button" className="btn btn-sm btn-primary" onClick={handleYes}>{props.actionLabel}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ModalNotify(props: ModalNotifyPropsI) {

    let modalRef = useRef<HTMLDivElement>(null);

    function close(){
        if(modalRef.current) {
            const modalCpt = Modal.getInstance(modalRef.current);
            if(modalCpt) {
                modalCpt.hide();
            }
        }

        PubSub.publish("modal.remove", props.id);
    }

    function handleClose() {
        props.handleClose();
        close();
    }

    useEffect(() => {
        if(modalRef.current) {
            const modal = Modal.getOrCreateInstance(modalRef.current, {
                backdrop: 'static'
            });
            modal.show();
        }
    });

    return (
        <div ref={modalRef} className="modal" tabIndex={-1} id={props.id}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-primary" onClick={handleClose}>{props.actionLabel}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ModalList(props: ModalListPropsI) {

    let modalRef = useRef<HTMLDivElement>(null);

    function close(){
        if(modalRef.current) {
            const modalCpt = Modal.getInstance(modalRef.current);
            if(modalCpt) {
                modalCpt.hide();
            }
        }

        PubSub.publish("modal.remove", props.id);
    }

    function handleClick(item: any) {
        props.handleAction(item);
        close();
    }

    useEffect(() => {
        if(modalRef.current) {
            const modal = Modal.getOrCreateInstance(modalRef.current);
            modal.show();
        }
    });

    return (
        <div ref={modalRef} className="modal" tabIndex={-1} id={props.id}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body mt-2 mb-2">
                        <p>
                            {props.description}
                        </p>
                        {props.list
                            .map((message: string, index: number) => <button
                                key={index}
                                className="btn btn-sm btn-secondary mt-2 mb-2 me-1"
                                onClick={() => handleClick(message)}
                            >
                                {message}
                            </button>)}
                    </div>
                </div>
            </div>
        </div>
    )
}


export function ModalsContainer() {

    const [modals, setModals] = useState<Array<{id: string, element:ReactElement}>>([]);

    useEffect(() => {

        function removeModal(channel: string, id: string) {
            setModals([...modals.filter(modal => modal.id !== id)]);
        }

        function removeAllModals(channel: string) {
            modals.forEach(modal => {
                const modalCpt = Modal.getInstance(`#${modal.id}`);
                if(modalCpt) {
                    modalCpt.hide();
                }
            });
            setModals([]);
        }

        function addConfirmModal(channel: string, data: {title: string, handleConfirm: () => void, actionLabel: string, content: ReactElement}) {
            const id = nanoid();
            const modal = <ModalConfirm key={modals.length} id={id} title={data.title} handleConfirm={data.handleConfirm} actionLabel={data.actionLabel}>{data.content}</ModalConfirm>
            setModals([...modals, {id: id, element: modal}]);
        }

        function addNotifyModal(channel: string, data: {title: string, handleClose: () => void, actionLabel: string, content: ReactElement}) {
            const id: string = nanoid();
            const modal = <ModalNotify key={modals.length} id={id} title={data.title} handleClose={data.handleClose} actionLabel={data.actionLabel}>{data.content}</ModalNotify>
            setModals([...modals, {id: id, element: modal}]);
        }

        function addListModal(channel: string, data: {handleAction: (item: any) => void, description: string, list: Array<any>}) {
            const id = nanoid();
            const modal = <ModalList key={modals.length} id={id} handleAction={data.handleAction} description={data.description} list={data.list} />
            setModals([...modals, {id: id, element: modal}]);
        }

        let addConfirmModalEvt = PubSub.subscribe("modal.confirm.add", addConfirmModal);
        let addNotifyModalEvt = PubSub.subscribe("modal.notify.add", addNotifyModal);
        let addlistModalEvt = PubSub.subscribe("modal.list.add", addListModal);
        let removeModalEvt = PubSub.subscribe("modal.remove", removeModal);
        let closeModalEvt = PubSub.subscribe("modal.close", removeAllModals);

        return () => {
            PubSub.unsubscribe(addConfirmModalEvt);
            PubSub.unsubscribe(addNotifyModalEvt);
            PubSub.unsubscribe(addlistModalEvt);
            PubSub.unsubscribe(removeModalEvt);
            PubSub.unsubscribe(closeModalEvt);
        }

    });

    return(
        <>{
            modals.map(modal => modal.element)
        }</>
    );
}

export function createConfirmModal(title: string, content: ReactElement, label: string) {
    return new Promise(resolve => {
        PubSub.publish("modal.confirm.add", { title: title, handleConfirm: resolve, actionLabel: label, content: content});
    });
}

export function createNotifyModal(title: string, content: ReactElement, label: string|ReactElement) {
    return new Promise(resolve => {
        PubSub.publish("modal.notify.add", { title: title, handleClose: resolve, actionLabel: label, content: content});
    });
}

export function createListModal(action: (item: any) => void, description: string, list: Array<any>) {
    PubSub.publish("modal.list.add", { handleAction: action, description: description, list: list});
}

export function closeModals() {
    PubSub.publish("modal.close");
}