import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getParam } from '../../urlUtils';
import { getInternalMessages, deleteInternalMessage, getInternalMessage } from '../../api'
import moment from 'moment';
import Spinner from '../element/spinner';
import Pagination from '../element/pagination';
import EmptyList from '../element/emptyList';
import { PaginatedList, InternalMessageI, InternalMessageInfoI } from '../../interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createNotifyModal, createConfirmModal } from '../element/modal';
import { AppContext } from '../../context';
import { faTrash, faArrowLeft, faEnvelope, faEnvelopeOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';


export interface MessageListItemProps {
    list:Array<InternalMessageI>,
    noItemLines: number,
    noItemMessage: string,
    handleNeedRefresh: () => void
}

export async function showInternalMessageModal(infos:InternalMessageInfoI, translation:any, seenHandler?:(messageId:string)=>void) {
    
    const t = translation
    const message:InternalMessageI = await getInternalMessage(infos.id);
    const dateFormat = t("global.short_date_format_hours", "DD/MM HH:mm");

    createNotifyModal(
        message.subject || t("int_messages.view_message_modal.title", "Message Porthello"), <div>
            <div className="fst-italic">
                {t("int_messages.view_message_modal.date", {
                    defaultValue: "Le {{date}}",
                    date: moment(message.createdAt).format(dateFormat)
                })}
            </div>
            <hr className="mt-1 mb-2" />
            <div dangerouslySetInnerHTML={{ __html: message.content||"" }} />
        </div>, t("int_messages.view_message_modal.button.close", "Fermer"))

    seenHandler && seenHandler(message.id)

}
export function InternalMessagesListItem({ list, noItemLines, noItemMessage, handleNeedRefresh }: MessageListItemProps) {

    const { t } = useTranslation('main');
    const [messages, setMessages] = useState<Array<InternalMessageI>>(list);
    const { setNeedRefreshNewInternalMessageCount } = useContext(AppContext);

    useEffect(() => {
        setMessages(list)
    }, [list]);

    async function handleDeleteMessage(event: React.MouseEvent<HTMLDivElement>, messageId:string) {

        event.stopPropagation();

        const confirm = await createConfirmModal(t("int_messages.delete_message_modal.title", "Supprimer"), <p>
                {t("int_messages.delete_message_modal.message", "Confirmez-vous vouloir supprimer ce message ?")}
            </p>,t("int_messages.delete_message_modal.button.confirm", "Confirmer"))

        if(!confirm) {
            return;
        }

        await deleteInternalMessage(messageId);

        handleNeedRefresh();
    }

    function onMessageSeen(messageId:string) {
        try{
            // Update message list with message flag as seen
            setMessages(messages?.map(item=>{
                if ( item.id === messageId ) {
                    item.seen = true
                }
                return item
            }))
            setNeedRefreshNewInternalMessageCount( true )
        } catch (_){}
    }

    async function showInternalMessage(infos:InternalMessageInfoI) {
        return showInternalMessageModal( infos, t, onMessageSeen)
    }

    if(!messages.length) {
        return <EmptyList lines={noItemLines || 3} message={noItemMessage} />;
    }

    const dateFormat = t("global.short_date_format_hours", "DD/MM HH:mm");

    return (
        <ul className="list-group w-100" >
            {messages.map((infos:InternalMessageInfoI) =>
                <li key={infos.id} className="list-group-item cursor-pointer" onClick={()=>showInternalMessage(infos)}>
                    <div className="d-flex flex-row align-items-center">
                        <div className="pe-2 d-none d-sm-block">
                            <div className="icon-wrapper-circle icon-main-background">
                                <FontAwesomeIcon icon={infos.seen? faEnvelopeOpen : faEnvelope } />
                            </div>
                        </div>
                        <div className="flex-grow-1">
                            <div className={`d-flex flex-row justify-content-between ${infos.seen ? '' : 'fw-bold'}`}>
                                <div className="flex-grow-1">
                                    {infos.subject || t("int_messages.list_item.subject", "Message Porthello")}
                                </div>
                                <div>
                                    {moment(infos.createdAt).format(dateFormat)}
                                </div>
                                <div className="font-primary cursor-pointer" onClick={(event) => handleDeleteMessage(event, infos.id)}>
                                    <FontAwesomeIcon icon={faTrashAlt} className="ms-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )}
        </ul>
    )
}
interface InternalMessagesPaginatedListProps extends PaginatedList<InternalMessageI> {
    handleNeedRefresh:()=>void
}

function InternalMessagesPaginatedList({rows, page, totalPages, handleNeedRefresh}:InternalMessagesPaginatedListProps) {

    const { t } = useTranslation('main');

    return (
        <React.Fragment>
            <InternalMessagesListItem
                list={rows}
                noItemLines={9}
                noItemMessage={t("int_messages.list.no_item", "Aucun message")}
                handleNeedRefresh={() => {
                    handleNeedRefresh()
                }}
            />
            {totalPages > 1
                ? <Pagination page={page} totalPages={totalPages} />
                : null}
        </React.Fragment>
    );
}

function MessagesList() {

    const { t } = useTranslation('main');
    const { setNeedRefreshNewInternalMessageCount, newInternalMessageCount } = useContext(AppContext);
    const [ internalMessages, setInternalMessages ] = useState<PaginatedList<InternalMessageI>>();
    const [ messageCount, setMessageCount ] = useState<number>(newInternalMessageCount);
    const [ searchParams ] = useSearchParams();
    const page = getParam(searchParams, "page");

    function refresh() {
        let params = page && !isNaN(page as any)
            ? {page: Number(page)}
            : undefined;
            getInternalMessages(params)
            .then((data: PaginatedList<InternalMessageI>) => {
                setInternalMessages(data);
                setNeedRefreshNewInternalMessageCount( true )
            })
    }

    useEffect(() => {
        refresh();
    }, [page]);

    useEffect(() => {
        if(newInternalMessageCount > messageCount){
            refresh();
        }
        setMessageCount(newInternalMessageCount);
    }, [newInternalMessageCount]);

    if (internalMessages === undefined) {
        return <Spinner />;
    }

    return (
        <main className="flex-grow-1 dashboard">
            <Link to="/" className="d-lg-none">
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
            </Link>
            <h3 className="flex-grow-1 mt-3 mb-3">
                {t("int_messages.page.title", "Messages reçus")}
            </h3>
            <InternalMessagesPaginatedList {...internalMessages} handleNeedRefresh={() =>{refresh()}}/>
        </main>
    );

}

export default MessagesList;