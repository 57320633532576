import React from 'react';
import { Routes, Route } from "react-router-dom";
import MessagesList from '../routes/protected/messages';


export default function MessagesRoutes() {
    return (
        <Routes>
            <Route index element={<MessagesList />} />
        </Routes>
    )
}