import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './css/spinner.scss';

function Spinner() {

    const { t } = useTranslation('main');

    return (
        <div className="d-flex page-center-spinner spinner">  
            <div className="d-flex flex-column">
                <FontAwesomeIcon icon={faCircleNotch} className="big-icon fa-spin m-3"/>
                {t("global.loading.message", "Chargement des données ...")}
            </div>
        </div>
    );
}

export default function Lazy({children}: {children: ReactNode}) {
    return (
        <React.Suspense fallback={<Spinner />}>
            {children}
        </React.Suspense>
    );
}

