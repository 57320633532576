import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PaginationProps } from '../../interfaces'

function Pagination(props:PaginationProps) {

    const { t } = useTranslation('main');

    let nav = [];
    let indexSize = 3;
    let prevDisabled = props.page - 1 <= 0 ? "disabled" : "";
    let nextDisabled = props.page + 1 > props.totalPages ? "disabled" : "";
    let startIndex = Math.max(props.page-Math.round(indexSize/2),1);
    let endIndex = Math.min(startIndex+indexSize, props.totalPages);

    startIndex += startIndex+indexSize > props.totalPages
        ? props.totalPages - (startIndex + indexSize)
        : 0;

    if(startIndex < 1) {
        startIndex = 1;
    }

    for(let page=startIndex; page<=endIndex; page++) {
        const active = page === props.page ? "active" : "";
        nav.push(<li className={`page-item ${active}`} key={page}>
            <Link to={`?page=${page}`} className="page-link">
                {page}
            </Link>
        </li>)
    }

    return props.totalPages > 1 ?(
        <nav className="sticky-pagination-bar sticky-bottom">
            <ul className="pagination justify-content-center mb-0">
                <li className={`page-item ${prevDisabled}`}>
                    <Link to={`?page=${props.page-1}`} className="page-link">
                        {t("global.pagination.link.previous", "Précédent")}
                    </Link>
                </li>
                {nav}
                <li className={`page-item ${nextDisabled}`}>
                    <Link to={`?page=${props.page+1}`} className="page-link">
                        {t("global.pagination.link.next", "Suivant")}
                    </Link>
                </li>
            </ul>
        </nav>
    ) : null;
}

export default Pagination;