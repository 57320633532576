interface DataI {
    [key: string]: any
}

class Config {

    [key: string]: any

    constructor() {
        this.callBaseUrl = window.location.origin;
        this.apiBaseUrl = window.location.origin;
        this.wsBaseUrl = window.location.origin;
        this.autoCall = false;
        this.appId = "38077edb";
        this.appKey = "4f304359baa6d0fd1f9106aaeb116f33";
        this.stripeKey = undefined;
        this.callError = undefined;
        this.currencies = ["eur", "usd", "mxn"];
        this.countries = ["AF","ZA","AX","AL",
            "DZ","DE","AD","AO","AI","AQ","AG","SA","AR","AM","AW","AU","AT","AZ",
            "BS","BH","BD","BB","BY","BE","BZ","BJ","BM","BT","BO","BQ","BA","BW",
            "BV","BR","BN","BG","BF","BI","CV","KY","KH","CM","CA","CL","CN","CX",
            "CY","CC","CO","KM","CG","CD","CK","KR","KP","CR","CI","HR","CU","CW",
            "DK","DJ","DO","DM","EG","SV","AE","EC","ER","ES","EE","US","ET","FK",
            "FO","FJ","FI","FR","GA","GM","GE","GS","GH","GI","GR","GD","GL","GP",
            "GU","GT","GG","GN","GW","GQ","GY","GF","HT","HM","HN","HK","HU","IM",
            "UM","VG","VI","IN","IO","ID","IR","IQ","IE","IS","IL","IT","JM","JP",
            "JE","JO","KZ","KE","KG","KI","KW","LA","LS","LV","LB","LR","LY","LI",
            "LT","LU","MO","MK","MG","MY","MW","MV","ML","MT","MP","MA","MH","MQ",
            "MU","MR","YT","MX","FM","MD","MC","MN","ME","MS","MZ","MM","NA","NR",
            "NP","NI","NE","NG","NU","NF","NO","NC","NZ","OM","UG","UZ","PK","PW",
            "PS","PA","PG","PY","NL","XX","ZZ","PE","PH","PN","PL","PF","PR","PT",
            "QA","SY","CF","RE","RO","GB","RU","RW","EH","BL","KN","SM","MF","SX",
            "PM","VA","VC","SH","LC","SB","WS","AS","ST","SN","RS","SC","SL","SG",
            "SK","SI","SO","SD","SS","LK","SE","CH","SR","SJ","SZ","TJ","TW","TZ",
            "TD","CZ","TF","TH","TL","TG","TK","TO","TT","TN","TM","TC","TR","TV",
            "UA","UY","VU","VE","VN","WF","YE","ZM"];
    }

    set(data: DataI) {
        Object.keys(data).forEach((key: string) => {
            if (key in this && typeof data[key] !== "undefined") {
                this[key] = data[key];
            }
        });
    }

    get(name: string) {
        return this[name]
    }
}

const config = new Config ();

export const setConf = config.set.bind(config);
export const getConf = config.get.bind(config);